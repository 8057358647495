.toplist {
	margin-left: 400px;
	*zoom: 1;
	
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}

.toplist__nav {
	margin-bottom: 20px;
	display: table;
	width: 100%;
	border-bottom: 1px solid gainsboro;
	
	li {
		display: table-cell;
		width: 33%;
		
		a {
			padding: 6px 10px 4px 10px;
			font-size: 10px;
			line-height: 1.2;
			color: #737373;
			text-transform: uppercase;
			text-decoration: none;
			text-align: center;
			letter-spacing: 0.1em;
			display: block;
			
			&:hover {
				color: black;
			}
		}
	}
	
	.is-active {
		background: gainsboro;
		
		a {
			color: black;
		}
	}
}

@media only screen and (max-width: 700px) {
	.toplist {
		margin-left: 0;
		margin-top: 20px;
	}
}

@media only screen and (max-width: 1150px) {
	.toplist__nav {
		margin-right: 0;
	}
}