article.article {
    .article-subscription {
        //margin-top: $gap;
        //border-top: 1px solid #dcdcdc;
        padding-top: $gap;

        color: #737373;
        font-size: 15px;
        line-height: 1.5;

        & h3 {
            font-weight: normal;
            font-size: 16px;
            line-height: 1.5;
            padding: 0;
        }

        & li {
            display: inline-block;
            width: 24%;
            min-width: 120px;
            margin-top: 10px;
        }

        & a {
            color: #737373;
            text-decoration: none;
            text-align: center;
            padding: 5px 10px;
            display: block;
            border-radius: 4px;
            background-color: #f9f9fbff;

            &:hover {
                //color: #ed1c24;
            }
        }

    }
}
