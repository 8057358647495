@font-face {
  font-family: 'fontello';
  src: url('fonts/fontello.eot?6986880');
  src: url('fonts/fontello.eot?6986880#iefix') format('embedded-opentype'),
       url('fonts/fontello.woff2?6986880') format('woff2'),
       url('fonts/fontello.woff?6986880') format('woff'),
       url('fonts/fontello.ttf?6986880') format('truetype'),
       url('fonts/fontello.svg?6986880#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-calendar:before {
    content: '\e800';
}

.icon-right:before {
    content: '\e801';
}

.icon-left:before {
    content: '\e802';
}

.icon-down:before {
    content: '\e803';
}

.icon-star:before {
    content: '\e804';
}

.icon-camera:before {
    content: '\e805';
}

.icon-video-chat:before {
    content: '\e806';
}

.icon-search:before {
    content: '\e807';
}

.icon-cancel:before {
    content: '\e808';
}

.icon-up-open:before {
    content: '\e809';
}

.icon-down-open:before {
    content: '\e80a';
}

.icon-up:before {
    content: '\e80b';
}

.icon-patreon:before {
    content: '\e80c';
}

.icon-x-twitter:before {
    content: '\e80d';
}

.icon-eye:before {
    content: '\e810';
}

.icon-comment:before {
    content: '\e811';
}

.icon-twitter:before {
    content: '\f099';
}

.icon-facebook-1:before {
    content: '\f09a';
}

.icon-rss:before {
    content: '\f09e';
}

.icon-menu:before {
    content: '\f0c9';
}

.icon-gplus:before {
    content: '\f0d5';
}

.icon-ellipsis:before {
    content: '\f141';
}

.icon-ellipsis-vert:before {
    content: '\f142';
}

.icon-video:before {
    content: '\f167';
}

.icon-instagram:before {
    content: '\f16d';
}

.icon-vk:before {
    content: '\f189';
}

.icon-paypal:before {
    content: '\f1ed';
}

.icon-odnoklassniki:before {
    content: '\f263';
}

.icon-telegram:before {
    content: '\f2c6';
}
