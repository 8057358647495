#donate-banner {
    border-top: 1px solid #ccc;
    padding-top: 30px;
    margin-top: 30px;

    font-size: 15px;
    line-height: 1.5;

    .row {
        margin-bottom: $gap;

        &.donate {
            text-align: center;
            margin-bottom: 40px;
        }

        & > a {
            display: inline-block;
            border: 1px solid #737373;
            border-radius: 3px;
            padding: 15px 0;
            width: 240px;
            box-shadow: 0 0 10px #a6a6a6;

            &:first-child {
                margin-right: 40px;
            }

            &:hover {
                background-color: rgb(249, 249, 251);
            }

            & > img {
                height: 48px;
                width: auto;
                margin: 0 auto;
            }
        }
    }
}

@media only screen and (max-width: 760px) {
    #donate-banner {
        .row {
            & > a {
                &:first-child {
                    margin-right: 0;
                    margin-bottom: $gap;
                }
            }
        }
    }
}
