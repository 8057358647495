.footer_categories {
	margin-top: 12px;
	padding-bottom: 30px;
	border-bottom: 1px solid gainsboro;
	
	ul {
		font-size: 0;
		margin-right: -20px;
		overflow: hidden;
	}
	
	li {
		display: inline-block;
		position: relative;
		margin-right: 20px;
		line-height: 2.4;
		font-size: 11px;
		font-family: $font-family;
		font-weight: normal;
		letter-spacing: 0.08em;
		text-transform: uppercase;
		
		&:before {
			content: "";
			position: absolute;
			top: 11px;
			left: -11px;
			width: 3px;
			height: 3px;
			border-radius: 50%;
			background: gainsboro;
		}
		
		a {
			text-decoration: none;
			color: black;
			
			&:hover {
				color: #ed1c24;
			}
			
			//&:visited {
			//	color: #999;
			//}
		}
	}
}