
.l.l_article .main-wrap {
    display: table;
    width: 100%;
}

.l.l_article .l-main {
    display: table-cell;
    vertical-align: top;
    padding-right: 30px;
    margin: 0;
    float: none;
}

.l.l_article .l-sidebar {
    width: $right-col-width;
    float: none;
    margin: 0;
    display: table-cell;
    vertical-align: top;
    border: none;
}

.l.l_article .l-sidebar__inner {
    width: $right-col-width;
}

.l.l_article .l-sidebar .weather {
    border: none;
}

.google_article_top {
	margin: 0 0 20px 0;
	clear: both;
}

@media only screen and (min-width: 1150px) {
	.google_article_top {
		display: none;
		clear: none;
	}
}

@media only screen and (max-width: 1150px){
	.l {
		.l-sidebar {
			width: $right-col-width;
			margin-left: -$right-col-width;
		}
		
		.l-sidebar__inner {
			width: $right-col-width;
		}
		
		.l-main {
			margin-right: $right-col-width + 20px;
			max-width: 684px;
		}
	}
}

@media only screen and (max-width:800px){
    .l.l_article .main-wrap,
    .l.l_article .l-main,
    .l.l_article .l-sidebar {
        display: block;
        padding: 0;
		max-width: 100%;
    }

    .l.l_article .l-sidebar,
    .l.l_article .l-sidebar__inner {
        width: auto;
    }
    
	.l {
		.l-main {
			margin-right: $right-col-width + 20px;
			max-width: 728px - ($right-col-width + 20px);
		}
		
		.l-sidebar {
			width: $right-col-width;
			margin-left: -($right-col-width + 20px);
		}
		
		.l-sidebar__inner {
			width: auto;
		}
	}
}

@media only screen and (max-width: 700px) {
	.l {
		.l-main {
			margin: 0 !important;
			float: none;
			width: auto;
			max-width: 100%;
		}
	}
}

.l_article {
	.l-sidebar {
		.opinion {
			display: none;
		}
	}
}

@media only screen and (max-width: 1024px) {
	.l_article {
		.l-sidebar {
			width: auto;
			margin-left: 0;
			display: block;
			
			.opinion {
				display: block;
				margin: 0;
				padding: 0 0 20px 0;
				border: none;
			}
		}
	}

	.footer {
		margin-top: 10px;
	}
}

.l-sidebar {
	.b.solidarity {
		padding: 0;
	}
}