#mobile-donate {
	display: none;
}

@media only screen and (max-width: 425px) {
	#mobile-donate {
		display: flex;
        border: 1px solid #ed1c24;
        border-radius: 3px;

		a {
            flex: 50%;
			padding: 10px 0;
			font-size: 12px;
			font-weight: bold;
			text-align: center;
			text-transform: uppercase;
			text-decoration: none;
			letter-spacing: 0.1em;
			color: #ed1c24;

            &.support {
                background-color: #ed1c24;
                color: #fff;
            }
		}

	}
}

@media only screen and (max-width: 360px) {
	#mobile-donate {
		a {
			font-size: 11px;

			& > span {
				display: block;
			}
		}
	}
}
