html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}

body, html {
    height: 100%;
    min-width: 320px;
}

img, fieldset, a img {
    border: none;
}

input[type="submit"], button {
    cursor: pointer;
}

input[type="submit"]::-moz-focus-inner, button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

textarea {
    overflow: auto;
}

input, button {
    margin: 0;
    padding: 0;
    border: 0;
}

div, input, textarea, select, button, h1, h2, h3, h4, h5, h6, a, span, a:focus {
    outline: none;
}

ul, ol {
    list-style-type: none;
}

table {
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
}

html {
    font-size: 62.5%;
}

body {
    font-family: $font-family;
    font-size: 12px;
    line-height: 1.3;
    color: black;
    background: white;
}

.gototop {
    position: fixed;
    bottom: -20px;
    right: 10px;
    border: 1px solid #cccccc;
    padding: 10px 10px;
    text-transform: uppercase;
    color: #cccccc;
    background-color: #fff;
    cursor: pointer;
    opacity: 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

.can-gototop .gototop {
    opacity: 1;
    bottom: 20px;
}

.oz {
    overflow: hidden;
    zoom: 1;
}

.oz .date_article_search {
    display: inline-block;
    width: 120px;
    height: 40px;
    margin: 0 5px 0 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: white;
    border: 1px solid #a6a6a6;
    font-family: $font-family;
    font-weight: normal;
    font-size: 15px;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
    vertical-align: top;
}

.oz .date_article_search:hover {
    color: #ed1c24;
}

.l {
    max-width: 1160px;
    padding: 0 60px;
    margin: auto;
    *zoom: 1;
}

.l:after {
    content: " ";
    display: table;
    clear: both;
}

.l .l-top {
    float: left;
    margin-right: $right-col-width + 30px;
    margin-bottom: 20px;
    *zoom: 1;
}

.l .l-top:after {
    content: " ";
    display: table;
    clear: both;
}

.l .l-main {
    float: left;
    margin-right: $right-col-width + 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.l .l-sidebar {
    float: right;
    width: $right-col-width;
    margin-left: -$right-col-width;
}

.l .l-sidebar__inner {
    width: $right-col-width;
}

.main-wrap {
    *zoom: 1;
    position: relative;
}

.main-wrap:after {
    content: " ";
    display: table;
    clear: both;
}

.header {
    margin: 20px 0;
    position: relative;
}

.header__right {
    width: 360px;
    text-align: center;
    display: inline-block;
    float: left;
}

.logo {
    background: url('img/logo_charter97-1.png') no-repeat;
    background-size: cover;
    width: 260px;
    height: 70px;
    font: 0/0 a;
    text-shadow: none;
    color: transparent;
    margin: 37px 0 20px 0;
    display: inline-block;
}

/*.logo{display:block;background:url('img/icons-sbd618a61bd.png') 0 -27px no-repeat;width:201px;height:55px;font:0/0 a;text-shadow:none;color:transparent;margin:19px 0 13px;} */
.header__date {
    color: #a6a6a6;
    font-size: 10px;
    font-size: 1rem;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    display: block;
}

.header__main {
    margin-left: 360px;
    padding-left: 40px;
}

.header__top {
    margin-bottom: 20px;
    padding-right: 50px;
    line-height: 1;
    text-align: right;
    text-transform: uppercase;
    font-size: 10px;
    font-size: 1rem;
    font-family: $font-family;
    font-weight: normal;
    letter-spacing: 0.1em;
    position: relative;
}

.header__top-item {
    white-space: nowrap;
}

.header__top a {
    text-decoration: none;
    color: #737373;
}

.header__top a:hover {
    color: black;
}

.header__top .pda {
    float: left;
}

.header__top .lang {
    display: inline-block;
    padding-right: 50px;
}

.header__top .lang a {
    margin-left: 10px;
}

.header__top .lang a.is-active {
    color: #ed1c24;
}

.header__top .send-news {
    margin: 0 32px 0 10px;
    color: #ed1c24;
}

.header .icon-search {
    display: block;
    font-size: 17px;
    cursor: pointer;
    position: absolute;
    top: -3px;
    right: 0;
}

.header .icon-search.is-inactive {
    color: #a6a6a6;
}

.header__topnews {
    margin: 20px 0;
    overflow: hidden;
    font-size: 0;
    height: 111px;
}

.header__topnews li {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    width: 200px;
    line-height: 1.15;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 11px;
    font-size: 1.1rem;
    border: 1px solid gainsboro;
}

.header__topnews li.header__topnews_mid {
    margin: 0 23px;
}

.header__topnews a {
    color: #3c3c3c;
    font-family: $font-family;
    font-weight: normal;
    text-decoration: none;
    display: block;
    font-size: 0;
}

.header__topnews a i {
    content: "";
    display: none;
    top: 0;
    right: 0;
    color: #ed1c24;
    font-size: 8px;
}

.header__topnews a:hover i {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 2px;
}

.header__topnews a:visited {
    color: #3c3c3c;
    font-family: $font-family;
    font-weight: normal;
    text-decoration: none;
}

.header__topnews a > span {
    padding: 0 4px;
    line-height: 1.2;
    display: table-cell;
    font-size: 11px;
    font-size: 1.1rem;
    width: 194px;
    height: 34px;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    border-top: 1px solid gainsboro;
}

.search {
    display: none;
    padding: 30px 0;
    background: #e6e6e6;
}

.search__main {
    padding: 30px 0;
    background: #e6e6e6;
}

.search__in {
    font-size: 0;
}

.search__in:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    width: 0;
    font-size: 0;
}

.search__input input {
    width: 100%;
    height: 40px;
    padding: 0 15px;
    border: 1px solid #cccccc;
    color: #3c3c3c;
    font-size: 15px;
    font-size: 1.5rem;
    font-weight: normal;
    background: white;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.search__input input:-ms-input-placeholder {
    color: #737373;
}

.search__input input:-moz-placeholder {
    color: #737373;
}

.search__input input::-moz-placeholder {
    color: #737373;
}

.search__input input::-webkit-input-placeholder {
    color: #737373;
}

.search__input input::placeholder {
    color: #737373;
}

.search__input.search__input_error {
    width: 440px;
}

.search__filter {
    position: absolute;
    top: 0;
    right: 23px;
    bottom: 0;
    font-size: 13px;
    font-size: 1.3rem;
    color: #3c3c3c;
    text-decoration: none;
    line-height: 40px;
}

.search__filter:before {
    content: "";
    display: inline-block;
    background: url('img/icons-sbd618a61bd.png') 0 -605px no-repeat;
    width: 9px;
    height: 11px;
    margin-right: 5px;
    position: relative;
    top: 1px;
}

.search__btn {
    display: inline-block;
    width: 120px;
    height: 40px;
    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: white;
    border: 1px solid #a6a6a6;
    font-family: $font-family;
    font-weight: normal;
    font-size: 15px;
    font-size: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.1em;
}

.search__btn:hover {
    color: #ed1c24;
}

.search__close {
    float: right;
    display: inline-block;
    font-family: $font-family;
    font-weight: normal;
    font-size: 10px;
    font-size: 1rem;
    line-height: 40px;
    text-decoration: none;
    text-transform: uppercase;
    color: #737373;
    letter-spacing: 0.1em;
}

.search__close:hover {
    color: black;
}

.icon-cancel:before {
    content: "";
    display: inline-block;
    position: relative;
    font-size: 17px;
    top: 2px;
    margin-right: 6px;
}

.search .search__input {
    display: inline-block;
    vertical-align: middle;
    width: 74.07407%;
    max-width: 800px;
    position: relative;
    margin: 0 0 10px 0;
}

.search .search__btn {
    margin-left: 20px;
    vertical-align: top;
}

.search__main .search__btn {
    float: right;
    margin-top: -50px;
}

.search__main .search__input {
    margin-right: 130px;
    margin-bottom: 10px;
}

.footer {
    margin-top: 70px;
    border-top: 2px solid #ed1c24;
    padding-bottom: 30px;
}



.sponsors {
    *zoom: 1;
    font-size: 0;
    margin-top: 23px;
}

.sponsors:after {
    content: " ";
    display: table;
    clear: both;
}

.sponsors .sponsors__item:last-child {
    margin-right: 0;
}

.footer__bottom {
    margin-top: 50px;
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.4;
    *zoom: 1;
}

.footer__bottom:after {
    content: " ";
    display: table;
    clear: both;
}

.footer__copy {
    margin-top: 30px;
    float: left;
}

.footer__copy i {
    font-style: normal;
    color: #ed1c24;
}

.footer__copy a {
    text-decoration: none;
    color: #ed1c24;
}

.footer__stats {
    float: right;
    *zoom: 1;
}

.footer__stats:after {
    content: " ";
    display: table;
    clear: both;
}

.footer__stats strong {
    display: block;
    font-weight: normal;
}

.footer__stats-item {
    float: left;
    margin: 10px 15px 0 0;
}

.footer__stats-item:last-child {
    margin-right: 0;
}

.footer__stats-item span {
    display: block;
    font-family: $font-family;
    font-weight: normal;
    color: #ed1c24;
}

.footer__stats-count {
    background: gainsboro;
    width: 90px;
    height: 32px;
    position: relative;
    top: 3px;
}

.news__pic {
    float: left;
    width: 120px;
    height: 76px;
    margin: -10px 10px 0 0;
    overflow: hidden;
}

.news__pic img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.news__time {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1;
    color: #a6a6a6;
    font-weight: normal;
}

.news__title {
    display: inline;
    font-size: 16px;
    font-size: 1.6rem;
    font-family: $font-family;
    font-weight: bold;
    line-height: 1.375;
}

.news__title a {
    color: black;
    text-decoration: none;
}

a:hover {
    color: #ed1c24;
}

.news__title:hover {
    color: #ed1c24;
}

.news .news__counter {
    color: #ed1c24;
    text-decoration: none;
}

.news .news__counter:hover {
    color: #ed1c24;
    text-decoration: underline;
}

.news .news_latest .news__counter {
    color: #ed1c24;
    text-decoration: none;
}

.news .news_latest .news__counter:hover {
    color: #ed1c24;
    text-decoration: underline;
}

.topnews .news__counter {
    color: #ed1c24;
    text-decoration: none;
}

.topnews .news__counter:hover {
    color: #ed1c24;
    text-decoration: underline;
}

.sidebar-interview .news__counter {
    color: #ed1c24;
    text-decoration: none;
}

.sidebar-interview .news__counter:hover {
    color: #ed1c24;
    text-decoration: underline;
}

.news__updated, .news__type {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1;
    color: #737373;
    text-transform: uppercase;
    font-weight: normal;
    letter-spacing: 0.1em;
}

.news__counter {
    font-size: 12px;
    font-size: 1.2rem;
    line-height: 1;
    font-family: $font-family;
    font-weight: normal;
    color: #ed1c24;
}

.news__counter.news__counter_square {
    display: inline-block;
    margin-left: 8px;
    position: relative;
    color: white;
    font-size: 11px;
    font-size: 1.1rem;
}

.news__counter.news__counter_square:after {
    content: "";
    position: absolute;
    z-index: -1;
    background: #ed1c24;
    top: -4px;
    right: -3px;
    bottom: -3px;
    left: -4px;
}

.news__views {
    display: inline-block;
    position: relative;
    padding-right: 14px;
    color: #737373;
    font-family: $font-family;
    font-weight: normal;
    font-size: 12px;
    font-size: 1.2rem;
}

.article__header .icon-eye {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    color: #a6a6a6;
}

.news {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.4;
    color: #3c3c3c;
    *zoom: 1;
}

.news:after {
    content: " ";
    display: table;
    clear: both;
}

.news li {
    float: left;
    margin-bottom: 23px;
    position: relative;
    display: block;
    width: 100%;
}

.news li:last-child {
    margin-bottom: 0;
}

.news a {
    text-decoration: none;
    color: black;
}

.news .news__title {
    padding-top: 2px;
}

.news .news__title:hover {
    color: #ed1c24;
}

.news.news_latest {
    margin-top: 10px;
    padding-left: 45px;
}

.news.news_latest .news__time {
    position: absolute;
    top: 4px;
    left: -45px;
}

.news.news_latest .news__title {
    font-size: 18px;
    font-size: 1.8rem;
}

.news.news_latest .news__pic {
    margin: 0 10px 0 0;
    position: relative;
    top: 5px;
}

.news.news_week {
    line-height: 1.2;
}

.news.news_week li {
    float: none;
    margin-bottom: 17px;
}

.news.news_week li:last-child {
    margin-bottom: 0;
}

.news.news_week .news__time {
    margin-right: 8px;
}

.news.news_week .news__title {
    margin-right: 8px;
    font-size: 15px;
    font-size: 1.5rem;
}

.news__separator {
    width: 100%;
    height: 1px;
    margin: 50px 0;
    background-color: gainsboro;
    position: relative;
}

.news__separator span {
    position: absolute;
    z-index: 2;
    top: -10px;
    height: 20px;
    line-height: 20px;
    padding: 0 16px;
    background-color: #6a6a6a;
    font-family: $font-family;
    font-weight: normal;
    font-size: 10px;
    font-size: 1rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.news__separator.news__separator_first {
    background: none;
    margin-top: 0;
}

.news__separator.news__separator_first span {
    top: 0;
}

.news__separator_light {
    width: 100%;
    height: 1px;
    margin: 50px 0;
    background-color: #DDD;
    position: relative;
}

.news__separator_light span {
    position: absolute;
    z-index: 2;
    top: -10px;
    height: 20px;
    line-height: 20px;
    padding: 0 16px;
    background-color: #AAA;
    font-family: $font-family;
    font-weight: normal;
    font-size: 10px;
    font-size: 1rem;
    color: white;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.topnews {
    float: left;
    width: 360px;
    margin-right: 40px;
}

.topnews .topnews__pic {
    overflow: hidden;
    margin-bottom: 12px;
}

.topnews .topnews__pic img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    width: 100%;
}

.topnews p {
    margin-top: 10px;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.35;
}

.topnews .news__time {
    margin-right: 8px;
}

.topnews .news__title {
    display: inline;
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1.15;
}



.title {
    display: inline-block;
    margin-bottom: 15px;
    font-size: 11px;
    font-size: 1.1rem;
    font-family: $font-family;
    font-weight: normal;
    font-weight: normal;
    line-height: 1;
    text-transform: uppercase;
    color: #ed1c24;
    letter-spacing: 0.1em;
}

.pagi {
    text-align: right;
    margin: 25px 0 50px;
    padding-bottom: 20px;
    border-bottom: 1px solid gainsboro;
    *zoom: 1;
}

.pagi:after {
    content: " ";
    display: table;
    clear: both;
}

.pagi:last-child {
    margin-bottom: 0;
}

.btn {
    display: inline-block;
    height: 24px;
    border: 1px solid #a6a6a6;
    line-height: 26px;
    padding: 0 20px;
    text-decoration: none;
    color: black;
    font-size: 10px;
    font-size: 1rem;
    font-family: $font-family;
    font-weight: normal;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    position: relative;
}

.btn:hover {
    color: #ed1c24;
}

.btn.btn_prev {
    float: left;
    padding: 0 45px;
    margin: 0 40px 30px 0;
}

.icon-down {
    content: "";
    position: absolute;
    top: 0;
    left: 9px;
    float: left;
    color: #ed1c24;
    font-size: 10px;
}

.btn.btn_next {
    float: left;
    padding: 0 45px;
    margin-right: 40px;
}

.icon-right {
    content: "";
    position: absolute;
    top: 0;
    right: 9px;
    float: right;
    color: #ed1c24;
    font-size: 10px;
}

.btn.btn_archive {
    padding: 0 34px 0 44px;
}

.icon-calendar:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 16px;
    float: left;
    color: #ed1c24;
    font-size: 14px;
}

.icon-left {
    content: "";
    position: absolute;
    top: 0;
    left: 9px;
    float: left;
    color: #ed1c24;
    font-size: 10px;
}

.interview .interview__left {
    float: left;
    width: 360px;
    height: 230px;
    margin-right: 40px;
    overflow: hidden;
}

.interview .interview__left img {
    display: block;
    width: 100%;
}

.interview .interview__right {
    margin-left: 400px;
}

.interview .interview__right .news li:not(:last-child) {
    margin-bottom: 20px;
}

.interview .interview__pic {
    width: 100%;
    height: 100%;
    position: relative;
}

.interview .interview__caption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    color: white;
}

.interview .interview__caption .news__time {
    margin-right: 8px;
}

.interview .interview__caption .news__title {
    font-size: 18px;
    font-size: 1.8rem;
}

.interview .interview__caption .news__title:hover {
    color: #cccccc;
}

.b {
    padding-bottom: 15px;
    border-bottom: 1px solid gainsboro;
    margin-bottom: 15px;
}

.b:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.b a {
    text-decoration: none;
    color: #3c3c3c;
}

.b .title {
    line-height: 1.3;
}

.b .news__title {
    line-height: 1.25;
}

.banner {
    width: 100%;
    height: 300px;
    overflow: hidden;
    margin-bottom: 26px;
}

.photo-video {
    color: #3c3c3c;
    position: relative;
    margin-bottom: 15px;
}

.photo-video .pic {
    height: 140px;
    overflow: hidden;
    margin-bottom: 13px;
}

.photo-video .type {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
	color: #a6a6a6;
	font-size: 16px;
	background-color: #ffffff;
}

.photo-video img {
    display: block;
	width: 100%;
}

.photo-video .news__title:hover {
    color: #ed1c24;
}

.photo-video .news__time {
    display: block;
    font-size: 10px;
    font-size: 1rem;
    line-height: 2;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.weather {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.9;
}

.weather > ul {
    max-width: 220px;
}

.weather .weather__head {
    line-height: 1.3;
}

.weather .weather__head a:hover {
    color: #ed1c24;
}

.weather .weather__location {
    display: inline-block;
    font-family: $font-family;
    font-weight: normal;
    font-size: 11px;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.weather .weather__location a {
    color: black;
}

.weather .weather__location a:after {
    content: "";
    display: inline-block;
    background: url('img/icons-sbd618a61bd.png') 0 -192px no-repeat;
    width: 9px;
    height: 7px;
    margin-left: 3px;
}

.weather span {
    display: inline-block;
}

.weather .weather__day {
    width: 100px;
}

.weather .weather__ico {
    float: right;
    display: inline-block;
    position: relative;
    top: 4px;
}

.currency {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.9;
}

.currency span {
    display: inline-block;
}

.currency .currency__name {
    width: 80px;
}

.currency .currency__buy {
    width: 50px;
    text-align: right;
}

.currency .currency__sell {
    width: 50px;
    margin-left: 10px;
    text-align: right;
    color: #737373;
}

.subscription ul {
    font-size: 0;
}

.subscription li {
    display: inline-block;
    vertical-align: top;
    width: 50%;
    font-family: $font-family;
    font-weight: normal;
    font-size: 11px;
    font-size: 1.1rem;
    letter-spacing: 0.1em;
    line-height: 2.2;
    text-transform: uppercase;
}

.subscription a {
    color: #3c3c3c;
}

.subscription a:hover {
    color: #ed1c24;
}

.subscription .ico {
    position: relative;
    padding-left: 29px;
}

.subscription .ico:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
}

.subscription .icon-facebook-1:before,
.subscription .icon-video:before,
.subscription .icon-twitter:before,
.subscription .icon-x-twitter:before,
.subscription .icon-vk:before,
.subscription .icon-rss:before,
.subscription .icon-odnoklassniki:before,
.subscription .icon-gplus:before,
.subscription .icon-instagram:before,
.subscription .icon-telegram:before {
    font-size: 12px;
    margin: 0 10px 15px 0;
    color: #a6a6a6;
}

.sidebar-interview li {
    margin-bottom: 15px;
}

.sidebar-interview li:last-child {
    margin-bottom: 0;
}

.article {
    margin-top: 25px;
    margin-left: 90px;
    color: #3c3c3c;
}

.article h1 {
    display: inline;
    font-family: $font-family;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: 0.5px;
}

.article h2 {
    font-family: $font-family;
    font-weight: normal;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-bottom: 15px;
}

.article h2:not(:first-child) {
    margin-top: 30px;
}

.article p {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 22px;
}

.article a {
    text-decoration: none;
    color: #ed1c24;
}

.article a:hover {
    text-decoration: underline;
}

.article blockquote {
    margin: 22px 0;
    font-family: $font-family;
    font-weight: normal;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.33;
    padding-left: 38px;
    border-left: 2px solid #ed1c24;
}

.article img {
    display: block;
    width: 100%;
}

.article .lead {
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.4;
}

.article .article__header {
    margin-bottom: 40px;
}

.article .article__header h1 a {
    color: #3c3c3c;
    text-decoration: none;
}

.article .article__header h1 a:hover {
    color: #ed1c24;
    text-decoration: none;
}

.article .news__counter {
    margin-left: 5px;
}

.article .article__left {
    float: left;
    margin: 0 40px 30px -90px;
}

.article .article__photo {
    width: 360px;
}

.article .article__photo figcaption {
    margin-top: 15px;
    font-family: $font-family;
    font-weight: normal;
    font-size: 10px;
    font-size: 1rem;
    line-height: 1.4;
    text-transform: uppercase;
    color: #737373;
    letter-spacing: 0.1em;
}

.article .article__aside {
    width: 360px;
    padding: 15px 0 20px;
    border: 1px solid gainsboro;
    border-right: none;
}

.article .article__aside li {
    margin-bottom: 15px;
}

.article .article__aside li:last-child {
    margin-bottom: 0;
}

.article .article__aside a {
    color: #3c3c3c;
    text-decoration: none;
}

.article .article__aside a:hover {
    text-decoration: none;
}

.article .article__aside dl {
    font-size: 13px;
    font-size: 1.3rem;
    line-height: 1.54;
    color: #3c3c3c;
}

.article .article__aside dt {
    display: inline;
}

.article .article__aside dd {
    display: inline;
    color: #737373;
}

.article:after {
    clear: both;
}

.article__info {
    margin-top: 15px;
    font-size: 0;
}

.article__info li {
    display: inline-block;
    font-size: 10px;
    font-size: 1rem;
    text-transform: uppercase;
    color: #737373;
    letter-spacing: 0.08em;
    padding-right: 10px;
    border-right: 1px solid gainsboro;
    margin-right: 10px;
}

.article__info li:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: none;
}

.article__info i {
    font-style: normal;
    color: #ed1c24;
}

.article__info .news__views {
    font-size: 10px;
    font-size: 1rem;
}

.comments .addedCommentPlaceholder {
    margin: 0 5px 0 10.8%;
    padding: 7px 0 5px 0;
    border: #d8d3cb 1px dashed;
    background-color: #FAFAFA;
}

.comments .commentVoteSuccessMsgPlaceholder {
    position: absolute;
    margin: 0;
    padding: 5px 20px 5px 20px;
    border: #00AA00 1px dashed;
    background-color: #F0FFF0;
    white-space: nowrap;
    color: #00AA00
}

.comments .commentVoteErrorMsgPlaceholder {
    position: absolute;
    margin: 0;
    padding: 5px 20px 5px 20px;
    border: #EE8888 1px dashed;
    background-color: #FFF0F0;
    white-space: nowrap;
    color: #AA0000;
}

.comments .commentFormContainer {
    margin-top: 30px;
}

.social {
    margin: 0 0 22px;
    white-space: nowrap;
}

.social.social_placehold {
    text-transform: uppercase;
    font-size: 10px;
    font-family: $font-family;
    font-weight: normal;
    line-height: 30px;
}

.article__video {
    background: #f2f2f2;
    padding: 20px 20px;
    margin: 0
}

.article__video figcaption {
    margin-top: 20px;
    font-family: $font-family;
    font-weight: normal;
    font-size: 10px;
    font-size: 1rem;
    line-height: 1.4;
    color: #737373;
    text-transform: uppercase;
    letter-spacing: 0.1em;
}

.gallery {
    margin: 30px 0 20px;
    padding: 30px 0 80px;
    background: #f2f2f2;
    position: relative;
    width: 100%;
}

.gallery .gallery__top {
    max-width: 640px;
    margin: 0 auto 50px;
    padding: 0 40px;
    position: relative;
    font-size: 0;
}

.gallery .gallery__main {
    position: relative;
    padding: 0 80px;
}

.gallery .gallery__pager .slide {
    display: inline-block;
    opacity: 0.5;
    width: 120px !important;
    overflow: hidden;
    margin-right: 10px;
    cursor: pointer;
}

.gallery .gallery__pager .slide:last-child {
    margin-right: 0;
}

.gallery .gallery__pager .slide img {
    display: block;
    width: 100%;
}

.gallery .gallery__pager .slide.cycle-slide-active {
    opacity: 1;
}

.gallery .gallery__slides {
    overflow: hidden;
}

.gallery .gallery__slides .slide {
    width: 100%;
}

.gallery .gallery__prev, .gallery .gallery__next {
    display: block;
    position: absolute;
    z-index: 100;
    top: 60px;
    cursor: pointer;
}

.gallery .gallery__prev {
    background: url('img/icons-sbd618a61bd.png') 0 -577px no-repeat;
    width: 11px;
    height: 18px;
    left: 13px;
}

.gallery .gallery__next {
    background: url('img/icons-sbd618a61bd.png') 0 -549px no-repeat;
    width: 11px;
    height: 18px;
    right: 13px;
}

.gallery .gallery__conter {
    font-size: 15px;
    font-size: 1.5rem;
    color: #737373;
    position: absolute;
    bottom: 43px;
    right: 80px;
}

.gallery-old {
    margin: 20px 0 20px;
    padding: 20px 0 80px;
    background: #f2f2f2;
    position: relative;
    width: 100%;
}

.gallery-old .gallery__top {
    max-width: 640px;
    margin: 0 auto 0;
    padding: 0;
    position: relative;
    font-size: 0;
}

.gallery-old .gallery__main {
    position: relative;
    padding: 0 80px;
}

.gallery-old .gallery__pager .thumbnail {
    display: inline-block;
    width: 120px !important;
    overflow: hidden;
    margin-right: 10px;
    cursor: pointer;
}

.gallery-old .gallery__pager .thumbnail:last-child {
    margin-right: 0;
}

.gallery-old .gallery__pager .thumbnail img {
    display: block;
    width: 100%;
}

.article__footer {
    margin-top: 50px;
}

.article__footer .article__footer-news-list a {
    color: #3c3c3c;
    text-decoration: none;
}

.article__footer .article__footer-news-list li {
    margin-bottom: 18px;
}

.article__footer .article__footer-news-list li:last-child {
    margin-bottom: 0;
}

.article__footer .article__footer-news-list .news__title {
    margin: 0 5px;
    font-size: 15px;
    font-size: 1.5rem;
}

.article__footer .article__footer-btns {
    margin-top: 45px;
}

.article__footer .social_pluso {
    clear: both;
    margin-top: 20px;
}

.article__footer .btn_comment {
    float: left;
    color: black;
    margin-right: 20px;
    font-size: 11px;
    font-size: 1.1rem;
}

.article__footer .btn_comment:hover {
    color: #ed1c24;
    text-decoration: none;
}

.article__footer .btn_comment span {
    color: #ed1c24;
}

.article__footer .article__footer-bottom {
    margin-top: 25px;
    *zoom: 1;
    color: #737373;
    font-size: 13px;
    font-size: 1.3rem;
}

.article__footer .article__footer-bottom:after {
    content: " ";
    display: table;
    clear: both;
}

.article__footer .article__footer-bottom i {
    font-style: normal;
    color: #3c3c3c;
}

.article__footer .article__footer-bottom a {
    color: #3c3c3c;
    text-decoration: none;
}

.article__footer .article__footer-bottom a:hover {
    color: #ed1c24;
}

.error {
    text-align: center;
    margin: 115px 0 220px;
}

.error p {
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.3;
    margin: 20px;
}

.error p a {
    color: #ed1c24;
    text-decoration: none;
}

.error .error__title {
    font-family: $font-family;
    font-weight: normal;
    font-size: 48px;
    font-size: 4.8rem;
}

.error .error__subtitle {
    font-family: $font-family;
    font-weight: normal;
    font-size: 26px;
    font-size: 2.6rem;
    color: #737373;
}

.error .error__search {
    margin-top: 20px;
}

.error .error__search p {
    margin-bottom: 15px;
}

.rss__title {
    font-family: $font-family;
    font-weight: normal;
    font-size: 26px;
    font-size: 2.6rem;
    margin-bottom: 30px;
}

.rss__subtitle {
    display: block;
    color: #3c3c3c;
    font-size: 18px;
    font-size: 1.8rem;
    margin: 17px 15px;
}

.rss__list li {
    margin-bottom: 19px;
}

.rss__list li:last-child {
    margin-bottom: 0;
}

.rss__item {
    text-decoration: none;
    font-size: 15px;
    font-size: 1.5rem;
    color: #3c3c3c;
    line-height: 1.3;
}

.rss__item:hover {
    color: #ed1c24;
}

.rss__item span {
    display: inline-block;
    font-family: $font-family;
    font-weight: normal;
    color: #ed1c24;
    font-size: 11px;
    font-size: 1.1rem;
    text-transform: uppercase;
}

.rss__item span:before {
    content: "";
    display: inline-block;
    background: url('img/icons-sbd618a61bd.png') 0 -529px no-repeat;
    width: 10px;
    height: 10px;
    margin-right: 5px;
}

.rss__item.rss__item_lead {
    display: block;
    margin: 30px 0;
    font-size: 18px;
    font-size: 1.8rem;
}

.rss__item.rss__item_lead span {
    font-size: 15px;
    font-size: 1.5rem;
}

.text {
    color: #3c3c3c;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.33;
    margin-bottom: 35px;
}

.text h1, .text h2, .text h3 {
    font-family: $font-family;
    font-weight: normal;
    font-size: 26px;
    font-size: 2.6rem;
    line-height: 1.15;
    color: black;
    margin-bottom: 15px;
}

.text img {
    float: left;
    margin: 15px 40px 15px 0;
}

.text img:first-child {
    margin-top: 0;
}

.text p {
    margin-bottom: 15px;
}

.text ul {
    margin: 15px 0;
}

.text li {
    margin-bottom: 15px;
}

.text b, .text strong {
    font-weight: bold;
}

.comments {
    padding-top: 25px;
    border-top: 1px solid gainsboro;
    position: relative;
}

.comments:before {
    clear: both;
}

.comments__head {
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 18px;
    font-size: 1.8rem;
    line-height: 1.2;
    text-transform: uppercase;
    font-family: $font-family;
    font-weight: normal;
    letter-spacing: 0.1em;
}

.comments__head:first-child {
    margin-top: 0;
}

.comments__head .news__counter {
    margin-left: 5px;
}

.comments__info-text {
    font-size: 11px;
    font-size: 1.1rem;
    color: #a6a6a6;
    line-height: 1.2;
}

.comments__input {
    margin: 30px 0 0 90px;
    padding-left: 120px;
    position: relative;
    max-width: 410px;
    *zoom: 1;
}

.comments__input:after {
    content: " ";
    display: table;
    clear: both;
}

.comments__input label {
    position: absolute;
    top: 6px;
    left: 0;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.2;
}

.comments__input input, .comments__input textarea {
    display: block;
    width: 100%;
    box-sizing: border-box;
    background: white;
    box-shadow: none;
    border: 1px solid #737373;
    padding: 5px;
    font: normal 15px/1.2 Arial;
    color: #3c3c3c;
}

.comments__input .comments__info-text {
    position: absolute;
    top: 100%;
    margin-top: 5px;
}

.comments__input .comments__submit {
    margin-top: 20px;
    float: left;
}

.comments__input .comments__submit + .comments__info-text {
    position: static;
    margin-top: 20px;
    float: right;
}

.comments__input.is-marked .icon-comment {
    content: "";
    position: absolute;
    left: -90px;
    top: 5px;
    color: #a6a6a6;
    font-size: 17px;
}

.comments__submit {
    background: none;
    border: none;
    box-shadow: none;
    outline: white;
    font-size: 15px;
    font-size: 1.5rem;
}

.comment {
    margin-top: 20px;
    padding-left: 90px;
}

.icon-star:before {
    content: "";
    position: absolute;
    left: 0;
    color: #ffdf00;
    font-size: 14px;
    margin: 0 10px 0 0;
}

.comment.is-inactive .comment__content {
    color: #a6a6a6;
}

.comments .commentLevel2 {
    padding-left: 140px;
}

.comments .commentLevel3 {
    padding-left: 190px;
}

.comments .commentLevel4 {
    padding-left: 240px;
}

.comments .commentLevel5 {
    padding-left: 290px;
}

.comment__info {
    height: 20px;
    *zoom: 1;
    background: #f2f2f2;
    color: #a6a6a6;
    line-height: 20px;
    font-size: 11px;
    font-size: 1.1rem;
    padding: 0 10px;
    overflow: hidden;
}

.comment__info:after {
    content: " ";
    display: table;
    clear: both;
}

.comment__author {
    font-family: $font-family;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    letter-spacing: 0.1em;
}

.comment__author a {
    color: inherit;
}

.comment__rating {
    float: right;
    width: 70px;
    height: 20px;
    color: #737373;
    text-align: justify;
    font-size: 0;
    font-size: 12px;
    font-size: 1.2rem;
}

.comment__rating:after {
    content: "";
    display: inline-block;
    width: 100%;
    height: 0;
}

.comment__rating-change {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    line-height: 12px;
    text-align: center;
    color: inherit;
    text-decoration: none;
}

.comment__rating-change:hover {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.5);
}

.comment__rating-value {
    display: inline-block;
}

.comment__content {
    display: block;
    margin: 10px 0;
    font-size: 15px;
    font-size: 1.5rem;
    line-height: 1.4;
    word-wrap: break-word
}

.comment__answer-btn, .comments__submit {
    display: inline-block;
    font-family: $font-family;
    font-weight: normal;
    text-transform: uppercase;
    text-decoration: none;
    color: #ed1c24;
    letter-spacing: 0.1em;
}

.comment__answer-btn:hover, .comments__submit:hover {
    text-decoration: underline;
}

.form-elem input, .form-elem textarea {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
}

.search-row {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
}

.search-title {
    display: inline-block;
    vertical-align: middle;
}

.form-box {
    padding: 30px 0;
}

.form-box p {
    font-size: 15px;
    font-size: 1.5rem;
}

.form-m {
    margin: 4px 0 0 90px;
    padding-left: 120px;
    max-width: 410px;
}

.form-m .comments__info-text {
    margin-bottom: 10px;
}

.dark-link {
    color: #3c3c3c;
    text-decoration: none;
}

.dark-link:hover {
    color: #ed1c24;
}

.mb10 {
    margin-bottom: 10px;
}

.flr {
    float: right;
}

.error__validate input {
    border-color: #ed1c24;
}

.error-text {
    margin-top: 3px;
    color: #ed1c24;
}

.wrapper-capcha, .capcha-img {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
}

.wrapper-capcha {
    width: 150px;
    margin-right: 10px;
}

.capcha-img {
    border: 1px solid #91787f;
    height: 28px;
}

.capcha-img img {
    height: 100%;
    display: block;
}

.owl-carousel .owl-wrapper:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

.owl-carousel {
    display: none;
    position: relative;
    width: 100%;
    -ms-touch-action: pan-y;
}

.owl-carousel .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;
}

.owl-carousel .owl-wrapper-outer.autoHeight {
    -webkit-transition: height 500ms ease-in-out;
    -moz-transition: height 500ms ease-in-out;
    -ms-transition: height 500ms ease-in-out;
    -o-transition: height 500ms ease-in-out;
    transition: height 500ms ease-in-out;
}

.owl-carousel .owl-item {
    float: left;
    text-align: center;
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
}

.spons-item {
    margin: 0 10px;
}

.spons-item img {
    max-width: 100%;
}

.news a:visited .news__title,
.article__footer .article__footer-news-list a:visited {
    color: #999;
}

.search__main select {
    min-width: 60px;
}

.title a {
    text-decoration: none;
    color: #ed1c24;
}

.title a:hover {
    text-decoration: underline;
}

.title a:visited {
    text-decoration: none;
    color: #ed1c24;
}

@media only screen and (max-width: 450px) {
    .header__topnews li {
        display: none;
    }

    .header__topnews {
        display: none;
    }

    .header .icon-search {
        display: none;
    }

    .news__pic {
        display: none;
    }

    .news__pic img {
        display: none;
    }

    .form-m {
        margin-left: 0;
    }

    .comments__input {
        margin-left: 0;
    }

    .comment {
        padding-left: 0;
    }

    .comments .commentLevel2 {
        padding-left: 20px;
    }

    .comments .commentLevel3 {
        padding-left: 40px;
    }

    .comments .commentLevel4 {
        padding-left: 60px;
    }

    .comments .commentLevel5 {
        padding-left: 80px;
    }

    .icon-star {
        display: none;
    }

    .search__main select {
        min-width: 46px;
    }

    .oz .date_article_search {
        margin: 15px 0 0 10px;
        display: block;
    }
}

@media only screen and (max-width: 700px) {
    .header__topnews li {
        display: none;
    }

    .header__topnews {
        display: none;
    }

    .header .icon-search {
        display: none;
    }

    .form-m {
        padding-left: 0;
        max-width: 100%;
    }

    .btn.btn_prev {
        margin-right: 0;
    }

    .news__pic {
        display: none;
    }

    .l .l-sidebar__inner {
        width: auto;
    }

    .l .l-sidebar {
        float: none;
        width: auto;
        margin: 0;
    }

    .l .l-main, .topnews {
        margin: 0 !important;
        float: none;
        width: auto;
    }

    .gototop {
        display: none;
    }

    .footer__copy {
        float: none;
    }

    .footer__stats {
        float: none;
        margin-top: 10px;
    }

    .sponsors {
        display: none;
    }

    .header__right {
        float: none;
        width: auto;
        display: block;
        text-align: center;
    }

    .header__right .logo {
        margin: 0 auto;
    }

    .header__top-item {
        float: none !important;
    }

    .header__top-item.lang {
        margin-top: 10px;
    }

    .header__top {
        width: auto;
        text-align: center;
    }

    .search__input, .search__input.search__input_error {
        text-align: center;
        width: 100%;
    }

    .search__close {
        position: absolute;
        right: 20px;
        top: 3px;
        font-size: 0;
    }

    .search__btn {
        display: block;
        width: 100%;
        margin: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    .news__pic {
        float: none;
        width: auto;
        height: auto;
    }

    .news__pic img {
        display: block;
        float: none;
        margin-bottom: 10px;
    }

    .news__pic ~ p {
        margin-left: 0;
    }

    .header__main {
        margin: 0;
        padding: 0;
        margin-top: 15px;
        text-align: center;
    }

    .article {
        margin-left: 0;
        padding-top: 0;
        margin-top: 0;
    }

    .article .article__video {
        padding: 10px;
        margin: 0 0 1em 0;
        width: auto;
        height: auto !important;
    }

    .article .article__video .article__video-container {
        padding: 10px;
        width: auto;
        height: auto;
    }

    .article .article__video .article__video-container img {
        display: block;
        width: 100%;
    }

    .header__topnews li {
        display: block;
        width: auto;
    }

    .search__filter {
        display: none;
    }

    .btn {
        display: block;
        margin-bottom: 5px;
        margin-right: 0;
        float: none !important;
        text-align: center;
    }

    .article .article__left {
        width: auto !important;
        margin: 0 20px 1em 0 !important;
    }

    .comments__input {
        padding-left: 0;
        max-width: 100%;
    }

    .comments__input label {
        position: static;
        display: block;
        margin-bottom: 5px;
    }

    .search .search__input {
        width: 100%;
        max-width: 100%;
    }

    .search .search__btn {
        margin-left: 0;
    }

    .l .l-sidebar {
        display: none;
    }
}

@media only screen and (max-width: 1150px) {
    .article__footer .article__footer-bottom span:nth-child(2n-1) {
        display: none;
    }

    .interview .interview__right .news li:not(:last-child) {
        margin-bottom: 15px;
    }

    .interview .interview__right {
        margin-left: 335px;
    }

    .interview .interview__right .news__title {
        font-size: 15px;
        font-size: 1.5rem;
    }

    .interview .interview__right .news {
        line-height: 1.2;
    }

    .interview .interview__right {
        margin-left: 335px;
    }

    .interview .interview__right .news__title {
        font-size: 15px;
        font-size: 1.5rem;
    }

    .interview .interview__right .news {
        line-height: 1.2;
    }

    .interview .interview__left {
        width: 300px;
        margin-right: 35px;
    }

    .l .l-top {
        float: none;
        width: 100%;
        margin-right: 0;
    }

    .l .l-sidebar {
        width: 220px;
        margin-left: -220px;
    }

    .l .l-main {
        margin-right: 240px;
        max-width: 684px;
    }

    .l .l-sidebar__inner {
        width: 220px;
    }

    .l.l_article .l-main {
        margin-right: 0;
    }

    .l.l_article .l-sidebar {
        float: left;
        margin-left: 0;
        margin-top: 34px;
        padding-top: 15px;
        width: 100%;
        border-top: 1px solid gainsboro;
    }

    .l.l_article .l-sidebar__inner {
        width: 100%;
    }

    .l {
        padding: 0 20px;
    }

    .header__topnews li:last-child {
        display: none;
    }

    .sponsors .sponsors__item {
        margin-right: 10px;
    }

    .sponsors .sponsors__item:last-child {
        display: none;
    }

    .news__pic ~ p {
        margin-left: 130px;
    }

    .google_ads_bottom_no {
        display: none;
    }
}

.wrapper-sponsors {
    margin-top: 40px;

    .slick-slide {
        img {
            margin: 0 auto;
        }
    }
}
@media screen and (max-width: 760px) {
    .wrapper-sponsors {
        display: none;
    }
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
    .search-title_big {
        min-width: 66px;
    }
}

@media only screen and (min-width: 700px) and (max-width: 730px) {
    .search-title_small {
        min-width: 66px;
    }
}

@media only screen and (max-width: 630px) {
    .search-title_big {
        min-width: 66px;
    }
}

@media only screen and (max-width: 470px) {
    .search-title_small {
        min-width: 66px;
    }
}

@media only screen and (max-width: 400px) {
    .search-title {
        display: none;
    }

    .search-row select {
        margin: 0 10px 0 0 !important;
    }
}

@media only screen and (max-width: 850px) {
    .interview__right, .interview .interview__left {
        margin: 0 !important;
        float: none;
        width: auto;
    }

    .interview .interview__left {
        margin-bottom: 15px !important;
    }
}

@media only screen and (max-width: 1000px) {
    .search .search__input {
        width: 66%;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    * {
        -webkit-text-size-adjust: none;
    }
}

.dialog-social {
    position: fixed;
    display: none;
    background: #fff;
    z-index: 500;
    width: 445px;
}

.dialog-social-header {
    background: #3a5897;
}

.d-close {
    position: absolute;
    width: 22px;
    height: 22px;
    padding: 10px;

    right: 18px;
    top: 21px;
    cursor: pointer;
}

.dialog-social-fb .dialog-social-title {
    background: url(img/fb-dialog-bg.png) no-repeat 0 100%;
    text-align: left;
    color: #fff;
}

.dialog-social-title {
    font-size: 18px;
    line-height: 1.3em;
    padding-bottom: 20px;
}

.dialog-social-fb .dialog-social-widgets {
    padding-left: 25px;
}

.dialog-social-lnk {
    padding: 12px 0 10px 22px;
    display: inline-block;
}

.dialog-social .d-close {
    top: 7px;
    background: url(img/i.png) no-repeat -22px 0;
    padding: 0;
    width: 42px;
    height: 42px;
    right: 8px;
}

.dialog-social-message-block-text {
    border-top: solid 1px #9daccb;
    padding: 22px 66px 22px 22px;
}

.dialog-social-fb .dialog-social-title {
    background: url(img/fb-dialog-bg.png) no-repeat 0 100%;
    text-align: left;
    color: #fff;
}

.dialog-social-message-block-text {
    border-top: solid 1px #9daccb;
    padding: 22px 66px 22px 22px;
}

.fb_iframe_widget {
    display: inline-block;
    position: relative;
    margin-top: 20px;
    margin-bottom: 15px;
}

.fb_iframe_widget span {
    display: inline-block;
    position: relative;
    text-align: justify;
}

.fb_iframe_widget iframe {
    position: absolute;
}

.fb_iframe_widget iframe {
    position: absolute;
    z-index: 1;
}

.dialog-social-auth {
    padding: 12px 12px 20px 12px;
    text-align: center;
}

.dialog-social-auth-link {
    border-bottom: 1px dashed #000;
    text-decoration: none;
    font-size: 12px;
}

.dialog-social-header {
    background: #3a5897;
}

.dialog-social-fb .dialog-social-message-block-title {
    background-image: none;
    padding-bottom: 0;
}

.dialog-social-lnk {
    padding: 12px 0 10px 22px;
    display: inline-block;
}

.dialog-social-message-block-text {
    border-top: solid 1px #9daccb;
    padding: 22px 66px 22px 22px;
}

.dialog-social-bottom {
    bottom: -500px;
    right: -500px;
    width: 375px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    -moz-transform: none;
    -o-transform: none;
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
    display: block;
}
