.archive_search {
    margin-bottom: 30px;
    padding: 20px;
    background-color: #e6e6e6;

    & > select {
        margin-right: 20px;
        font-size: 1.5rem;
        height: 40px;
        min-width: 75px;
        text-align: center;
        border: 1px solid #a6a6a6;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
    }

    & > button {
        width: 120px;
        height: 40px;
        margin: 0;
        box-sizing: border-box;
        background: #fff;
        border: 1px solid #a6a6a6;
        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
        font-size: 1.5rem;
        text-transform: uppercase;
        letter-spacing: 0.1em;
    }
}

.archive_nav {
    margin: 10px 0 20px 0;

    & > a {
        display: inline-block;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: #000;
        padding: 5px 10px;
        margin: 0 10px 10px 0;
        border: 1px solid #ccc;

        &:hover {
            color: #ed1c24;
        }
    }
}

article {
    &.archive {
        font-size: 15px;
        line-height: 1.5;

        color: #3c3c3c;

        header {
            h1 {
                font-size: 28px;
                line-height: 1.2;
                letter-spacing: 0.5px;
                margin-bottom: 30px;
            }
        }

        .txt-block {
            h2 {
                font-size: 18px;
                line-height: 1.4;
                text-transform: none;
                letter-spacing: inherit;
                font-weight: bold;
            }

            dt {
                font-weight: bold;
            }

            h2, p, dt, dd {
                margin-bottom: 22px;
            }

            .signature {
                column-count: 3;
                font-style: italic;
                white-space: nowrap;
            }
        }

        .photo-block {
            .photo-item {
                border-bottom: 1px solid #cccccc;
                padding-bottom: 20px;
                margin-bottom: 20px;
                overflow: hidden;

                &:last-of-type {
                    border: none;
                    padding: 0;
                    margin: 0;
                }
            }

            img {
                width: 360px;
                float: left;
                margin: 0 20px 10px 0;
            }

            p::after {
                content: "";
                clear: both;
                display: block;
            }
        }

        .list-block {
            & > div {
                padding-bottom: 30px;
                margin-bottom: 30px;
                border-bottom: 1px solid #ccc;
                text-decoration: none;
                color: #000;
                clear: both;
                overflow: hidden;

                &:last-of-type {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                img {
                    width: 120px;
                    margin-right: 20px;
                    float: left;
                }

                h3 {
                    line-height: 17px;
                    margin-bottom: 6px;
                }

                a {
                    float: right;
                    color: #ed1c24;
                    text-decoration: none;
                }
            }

        }
    }
}

@media only screen and (max-width: 820px) {
    .archive_search {
        padding: 10px;

        & > select {
            margin-right: 6px;
            min-width: 60px;
        }

        & > button {
            width: 117px;
        }
    }
    article.archive {
        .txt-block {
            .signature {
                column-count: 2;
            }
        }

        .photo-block {
            img {
                margin-right: 0;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 420px) {
    .archive_search {
        padding: 5px;
        text-align: center;

        & > select {
            font-size: 1.4rem;
            margin: 0;
            min-width: 39px;
            height: 30px;
        }

        & > button {
            font-size: 1.4rem;
            letter-spacing: 0.05em;
            width: 85px;
            height: 30px;
        }
    }
    article.archive {
        .txt-block {
            .signature {
                column-count: 1;
            }
        }
        .list-block {
            & > div {
                h3 {
                    font-size: 14px;
                }
            }
        }
        & > header.article__header {
            margin-bottom: 10px;
        }
        & > figure.article__photo {
            width: 100% !important;
            margin: 0 0 20px 0 !important;
            float: none;
        }
    }
    .archive_nav {
        column-count: 2;
        & > a {
            width: 80%;
            text-align: center;
            white-space: nowrap;
        }
    }
}

@media only screen and (max-width: 320px) {
    article.archive {
        .list-block {
            & > div {
                img {
                    width: 100px;
                    margin-right: 10px;
                }
            }
        }
    }
}
