#article-comment-btns {
    a {
        display: block;
        text-decoration: none;
        margin-top: 40px;
        padding: 15px 0;
        border-radius: 3px;
        border: 1px solid #ccc;
        background-color: #f9f9fbff;
        font-size: 16px;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 1px;

        span {
            color: #737373;
            font-weight: normal;
            margin-left: 10px;
        }
    }
}
