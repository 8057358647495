.telegram-banner {
  margin-top: 10px;

  font-size: 15px;
  line-height: 1.5;

  &.telegram-charter {
    color: #336699;
  }
}

.twitter-banner {
    margin-top: 10px;
    font-size: 15px;
    line-height: 1.5;
    color: #4A9EE9;
    font-weight: bold;

    & > a {
        display: inline-block;
        padding: 0 7px 0 5px;
        background-color: #4A9EE9;
        color: #fff;
        border-radius: 3px;
        border: 1px solid #4A9EE9;
        margin: 0 5px;
        text-decoration: none;

        &:hover {
            text-decoration: none;
            background-color: #fff;
            color: #4A9EE9;
        }
    }
}
