#donate-article {
    text-align: center;
    border-top: 1px solid #ccc;

    & > a {
        text-decoration: none;
        display: inline-block;
        border: 1px solid #737373;
        border-radius: 3px;
        min-width: 240px;
        text-align: center;
        white-space: nowrap;
        padding: 10px 0;
        margin-top: 30px;
        letter-spacing: 1px;
        color: #000;

        &:first-child {
            background-color: #ed1c24;
            border: 1px solid #ed1c24;
            color: #fff;
        }

        &:nth-child(even) {
            margin-left: 30px;
        }

        & > b {
            display: block;
            font-size: 16px;
            margin-bottom: 5px;

            &.icon-telegram:before {
                color: #1b81b8;
                font-size: 23px;
                vertical-align: bottom;
                margin-right: 9px;
            }
        }
    }
}

@media only screen and (max-width: 760px) {
    #donate-article {
        & > a {
            &:nth-child(even) {
                margin-left: 0;
            }
        }
    }
}
