.opinion,
.interview {
	.opinion__item {
		display: block;
		color: #3c3c3c;
		*zoom: 1;
		margin-bottom: 12px;
		
		&:after {
			content: " ";
			display: table;
			clear: both;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
		
		a:visited {
			color: #999;
		}
	}
	
	.opinion__pic {
		float: left;
		width: 100px;
		height: 64px;
		overflow: hidden;
		margin: 0 10px 0 0;
		
		img {
			display: block;
			max-width: 100%;
		}
	}
	
	.opinion__content {
		margin-left: 70px;
	}
	
	.opinion__author {
		display: block;
		font-size: 10px;
		line-height: 2;
		color: #a6a6a6;
		text-transform: uppercase;
	}
}