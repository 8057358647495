$categories-spacing: 10px;
$categories-color-grey: #dcdcdc;
$categories-color-red: #ed1c24;
$categories-font-size: 12px;


#header_categories {
    border-top: 1px solid $categories-color-grey;
    border-bottom: 1px solid $categories-color-grey;
    margin: 15px 0 25px 0;
    padding-right: 40px;
    font-size: $categories-font-size;
    position: relative;
    height: 34px;
    overflow: hidden;

    .button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 40px;
        cursor: pointer;

        &:before {
            margin: 6px 0 0 17px;
            border-radius: 50%;
            border: 1px solid #737373;
            color: #737373;
            padding: 4px;
        }

        span {
            display: none;
            color: $categories-color-red;
            text-transform: uppercase;
            letter-spacing: 0.1em;
        }
    }

    ul {
        padding-top: $categories-spacing;
    }

    li {
        display: inline-block;
        position: relative;
        margin-right: 12px;

        &.support > a > span {
            background-color: #ed1c24;
            color: #fff;
            padding: 5px;
            border-radius: 3px;
            font-weight: bold;
            letter-spacing: 1px;
        }

        &:before {
            content: "";
            position: absolute;
            top: 5px;
            left: -10px;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background: $categories-color-grey;
            z-index: -1;
        }
    }

    a {
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        color: black;
        padding: 0 0 10px 0;
        display: block;

        &:hover {
            color: $categories-color-red;
        }
    }
}

@media only screen and (max-width: 768px) {
    #header_categories {
        position: initial;
        padding: 0;

        .button {
            display: block;
            width: auto;
            position: relative;
            padding: $categories-spacing 0;
            height: auto;

            &:before {
                position: absolute;
                margin: 0;
                top: 6px;
                right: 0;

            }

            span {
                display: initial;
            }
        }

        ul {
            padding: 0;
        }

        li {
            margin: 0;

            &.support {
                display: none;
            }

            &:before {
                content: none;
            }
        }

        a {
            padding: 5px;
            margin: 0 8px 10px 0;
            border-radius: 5px;
            background-color: #f4f4f4;
        }
    }
}
