
// Configuration

$font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;

$right-col-width: 336px;

$fancybox-image-url: "/assets/img";

$gap: 20px;



@import "styles/screen.scss";
@import "styles/archive.scss";
@import "styles/screen-fix.scss";
@import "styles/fontello.scss";
@import "styles/animation.scss";
@import "styles/adsbygoogle.scss";
@import "styles/categories_header.scss";
@import "styles/categories_footer.scss";
@import "styles/home_toplist.scss";
@import "styles/sidebar_opinion.scss";

@import "styles/mobile-donate.scss";
@import "styles/donate-banner.scss";
@import "styles/donate-article.scss";
@import "styles/telegram-banner.scss";
@import "styles/article-subscription.scss";
@import "styles/article-comments-btn.scss";

@import "node_modules/slick-carousel/slick/slick.scss";
@import "node_modules/fancybox/dist/scss/jquery.fancybox.scss";
